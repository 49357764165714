import { isFuture } from 'date-fns';
import React from 'react';

function formatDate(dateString, futureAsPresent = true) {
    if (futureAsPresent && (!dateString || new Date(dateString) > new Date())) return 'Present'; // Check if dateString is null or in the future
    const date = new Date(dateString);
    return date.toLocaleDateString('default', { month: 'short', year: 'numeric' });
}

function calculateDuration(start, end) {
    const startDate = new Date(start);
    const endDate = (!end || new Date(end) > new Date()) ? new Date() : new Date(end); // Use current date if end is null or in the future
    const months = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth();
    const years = Math.floor(months / 12);
    const remainderMonths = months % 12;

    return `${years > 0 ? `${years} ${years > 1 ? 'yrs' : 'yr'}, ` : ''} ${remainderMonths > 0 ? `${remainderMonths} ${remainderMonths > 1 ? 'mos' : 'mo'}` : ''}`.trim();
}

function renderDate(start, end) {
    const formattedStart = formatDate(start, false);
    if (isFuture(new Date(start))) return `Starting ${formattedStart}...`;

    const formattedEnd = formatDate(end);

    return `${formattedStart} - ${formattedEnd} . ${calculateDuration(start, end)}`;
}

function Employment({ data }) {
    return (
        <div id="employment-section" className="section">
            <h2>Employment</h2>
            {data.map((job, index) => (
                <div key={index} className="job-entry">
                    <h3>{job.job_title} at <a href={job.url} target="_blank" rel="noopener noreferrer">{job.company}</a></h3>
                    <p><strong>{renderDate(job.start, job.end)}</strong></p>
                    <h4>Tasks:</h4>
                    <ul className="task-list">
                        {job.tasks.map((task, taskIndex) => <li key={taskIndex} className="task-item">{task}</li>)}
                    </ul>
                </div>
            ))}
        </div>
    );
}

export default Employment;