import React, { useState } from 'react';

function Navigation() {
  const [isOpen, setIsOpen] = useState(false); // State to manage menu visibility

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle the state to show/hide the menu
  };

  const scrollToSection = (event) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href").slice(1);
    const targetSection = document.getElementById(targetId);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setIsOpen(false); // Close the menu after clicking a link
  };

  return (
    <nav className="navigation">
      <div className="hamburger" onClick={toggleMenu}>
        &#9776; {/* Unicode for the hamburger icon */}
      </div>
      <ul className={`menu ${isOpen ? "open" : ""}`}>
        <li><a href="#updates-section" onClick={scrollToSection}>Updates</a></li>
        <li><a href="#employment-section" onClick={scrollToSection}>Employment</a></li>
        <li><a href="#skills-section" onClick={scrollToSection}>Skills</a></li>
        <li><a href="#projects-section" onClick={scrollToSection}>Projects</a></li>
        <li><a href="#links-section" onClick={scrollToSection}>Links</a></li>
      </ul>
    </nav>
  );
}

export default Navigation;
