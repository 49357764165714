import React, { useState, useEffect } from 'react';
import Employment from './Components/Employment';
import Skills from './Components/Skills';
import Links from './Components/Links';
import Projects from './Components/Projects';
import data from './data.json';
import './App.css';
import { Analytics } from "@vercel/analytics/react"
import Updates from './Components/Updates';
import Navigation from './Components/Navigation';
import Cookies from 'js-cookie';

function App() {
  const useDarkMode = () => {
    // Initial state is determined by checking the cookie, then the system preference
    const [darkMode, setDarkMode] = useState(() => {
      const userPreference = Cookies.get('darkMode');
      if (userPreference !== undefined) {
        return userPreference === 'true';
      }
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    });

    useEffect(() => {
      // Update the body class and the cookie whenever the darkMode state changes
      document.body.classList.toggle('dark-mode', darkMode);
      Cookies.set('darkMode', darkMode, { expires: 365 }); // Set cookie to expire in 365 days
    }, [darkMode]);

    return [darkMode, setDarkMode];
  };

  const [darkMode, setDarkMode] = useDarkMode();

  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, [showTopBtn]);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div className="container">
      <Navigation /> {/* This line adds the navigation component */}
      <div className="theme-switch" onClick={() => setDarkMode(!darkMode)}>
        <div className={darkMode ? "lightbulb on" : "lightbulb off"}>
          {/* SVG or Font Icon for the Lightbulb goes here */}
          {darkMode ? (
            <img class="lightbulb-svg-glow" src="./bulb-on.svg" height="16px" width="16px" alt="Light mode"/>
          ) : (
            <img src="./bulb-off.svg" height="16px" width="16px" alt="Dark mode"/>
          )}
        </div>
      </div>
      <Updates data={data.latest} />
      <Employment data={data.employment} />
      <Skills skills={data.skills} links={data.external_links} />
      <Projects data={data.projects} />
      <Links data={data.links} />
      <Analytics />
      {showTopBtn && (
        <button className="top-to-btm" onClick={goToTop}>
          ↑
        </button>
      )}
    </div>
  );
}

export default App;
