import React from 'react';

function Skills({ skills, links }) {
    return (
        <div id="skills-section" className="section">
            <h2>Skills</h2>
            {skills.map((skillCategory, index) => (
                <div key={index}>
                    <h3>{skillCategory.name}</h3>
                    <div className="skills-wrapper">
                        {skillCategory.items.map((item, index) => (
                            links[item]
                            ? <a key={index} href={links[item]} target="_blank" rel="noopener noreferrer" className="skill-tag">{item}</a>
                            : <span key={index} className="skill-tag">{item}</span>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Skills;
