import React from 'react';

function Links({ data }) {
    return (
        <div id="links-section" className="section">
            <h2>Links</h2>
            {data.map((link, index) => (
                <a key={index} href={link.url} target="_blank" rel="noreferrer nooprener" className="link-tag">{link.name}</a>
            ))}
        </div>
    );
}

export default Links;
