function Projects({ data }) {
    return (
      <div id="projects-section" className="section projects">
        <h2>Projects</h2>
        {data.map((project, index) => (
          <div key={index} className="project">
            <h3>
              <a href={project.url} target="_blank" rel="noopener noreferrer">{project.name}</a>
            </h3>
            <p>{project.description}</p>
          </div>
        ))}
      </div>
    )
  }

  export default Projects;
