import React, { useState } from 'react';

const ITEMS_PER_PAGE = 2; // Set the number of items you want per page

function Updates({ data }) {
    const [currentPage, setCurrentPage] = useState(0);

    const pageCount = Math.ceil(data.length / ITEMS_PER_PAGE);
    const currentData = data.slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE);

    const changePage = (page) => {
        setCurrentPage(page);
    };

    const paginationItems = () => {
        const pages = [];
        for (let i = Math.max(0, currentPage - 2); i <= Math.min(pageCount - 1, currentPage + 2); i++) {
            pages.push(i);
        }
        return pages;
    };

    const renderPagination = () => {
        const pageNumbers = paginationItems();
        if (pageNumbers <= 1) return;

        return (
            <>
                <button onClick={() => changePage(0)} disabled={currentPage === 0}>
                    {'<<'}
                </button>

                {currentPage > 2 && <span>...</span>}

                {pageNumbers.map((page) => (
                    <button
                        key={page}
                        onClick={() => changePage(page)}
                        disabled={currentPage === page}
                    >
                        {page + 1}
                    </button>
                ))}

                {currentPage < pageCount - 3 && <span>...</span>}

                <button onClick={() => changePage(pageCount - 1)} disabled={currentPage === pageCount - 1}>
                    {'>>'}
                </button>
            </>
        );
    };

    return (
        <div id="updates-section" className="section">
            <h2>Updates & Insights</h2>
            {currentData.map((latest, index) => (
                <div key={index} className="job-entry">
                    <p className="updates-and-insights-date">{latest.date}</p>
                    <h2 className="updates-and-insights-title">{latest.title}</h2>
                    <p className="updates-and-insights-body" dangerouslySetInnerHTML={{ __html: latest.body }} />
                </div>
            ))}

            <div className="pagination-controls">
                {renderPagination()}
            </div>
        </div>
    );
}

export default Updates;
